import { defineStore } from "pinia";
import { ref } from "vue";
import type { StoreRecord, StoreRecordReference } from "./../services/store";
import { merge } from "lodash-es";

export type RecordsStoreMethods = {
	push: (records: StoreRecord[]) => void;
	unload: (records: StoreRecordReference[]) => void;
	get: (reference: StoreRecordReference) => StoreRecord | null;
	update: (reference: StoreRecordReference, input: any) => void;
};

export const useRecords = defineStore("records", () => {
	// data -------------------------------

	const list = ref<StoreRecord[]>([]);

	// methods -------------------------------

	function push(records: StoreRecord[]): void {
		list.value.push(...records);
	}

	function unload(references: StoreRecordReference[]): void {
		references.forEach((reference) => {
			const index = list.value.findIndex((item) => item.internals.reference === reference);
			if (index !== -1) {
				list.value.splice(index, 1);
			}
		});
	}

	function get(reference: StoreRecordReference): StoreRecord | null {
		return (list.value.find((item) => item.internals.reference === reference) as StoreRecord) || null;
	}

	function update(reference: StoreRecordReference, input: StoreRecord): void {
		const index = list.value.findIndex((item) => item.internals.reference === reference);

		if (index === -1) throw new Error("cannot find record " + reference);

		list.value[index] = merge(list.value[index], input) as StoreRecord;
	}

	// exports ---------------------------------

	return {
		list,
		push,
		unload,
		get,
		update,
	};
});
