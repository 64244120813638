import { ClientReloadPolicy } from "~/services/store";
import type { StoreQueryParams } from "~/services/store";

export default function (ssrOperations: any[], clientOperations: any[], params?: StoreQueryParams): boolean {
	if (process.client) {
		// prevent reloading data on client side if opted out, and data is already loaded
		if (params?.reloadOnClient === ClientReloadPolicy.never) {
			// look for operation that loaded on the server already
			if (ssrOperations.length > 0) {
				return true;
			}
			// look for operation that loaded on the client, because it never loaded on the server
			if (clientOperations.length > 0) {
				return true;
			}
		} else if (params?.reloadOnClient === ClientReloadPolicy.once) {
			// look for operation that loaded on the client
			if (clientOperations.length > 0) {
				return true;
			}
		}
	}

	return false;
}
