import { defineStore } from "pinia";
import type { StoreOperation, IStoreFindOperation } from "./../services/store";
import { StoreOperationTypes } from "./../services/store";

import { ref, computed } from "vue";
export type OperationsStoreMethods = {
	push: (operation: StoreOperation) => string;
	get: (id: string) => StoreOperation;
	update: (id: string, input: object) => void;
};

export const useOperations = defineStore("operations", () => {
	// data -------------------------------

	const list = ref<StoreOperation[]>([]);

	// Computed ------------------------------

	const saving = computed(() => {
		return list.value.filter((item) => item.processing).filter((item) => item.type === StoreOperationTypes.save).length !== 0;
	});

	const loading = computed(() => {
		return list.value.filter((item) => item.processing).filter((item) => item.type === StoreOperationTypes.find || StoreOperationTypes.query).length !== 0;
	});

	const fetching = computed(() => {
		return (
			list.value
				.filter((item) => item.processing)
				.filter((item) => item.type === StoreOperationTypes.find || StoreOperationTypes.query)
				.filter((item) => (item as IStoreFindOperation).count === 0).length !== 0
		);
	});

	// methods -------------------------------

	function push(operation: StoreOperation): string {
		list.value.push(operation);

		return operation.id;
	}

	function get(id: string): StoreOperation {
		return list.value.find((item) => item.id === id) as StoreOperation;
	}

	function update(id: string, input: object) {
		const index = list.value.findIndex((item) => item.id === id);
		if (index === -1) throw new Error("cannot find operation " + id);

		list.value[index] = { ...list.value[index], ...input } as StoreOperation;
	}

	// exports ---------------------------------

	return {
		list,
		saving,
		loading,
		fetching,
		push,
		get,
		update,
	};
});
