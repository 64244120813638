const list: { task: Promise<boolean>; id: string }[] = [];

export function register(id: string, task: Promise<boolean>) {
	// append to the list
	list.push({ id, task });

	// delete task from list when it's done
	task.finally(() => {
		list.splice(
			list.findIndex((item) => item.id === id),
			1
		);
	});
}

export function get(id: string): Promise<boolean> | undefined {
	return list.find((item) => item.id === id)?.task;
}
